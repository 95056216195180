import { EventEmitter, OnInit, Output, inject } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from '../layout.service';
import { MenuitemComponent } from './menuitem.component';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { SessionService } from '@core/auth/session.service';
import { ERoles } from '@shared/enum';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  standalone: true,
  imports: [NgFor, NgIf, MenuitemComponent, CommonModule],
})
export class MenuComponent implements OnInit {
  private sessionService = inject(SessionService);

  model: any[] = [];
  isRoles: any = [];
  isRol!: string;
  isExpand: string = 'btn-bottom-logout';

  @Output()
  onExpand: EventEmitter<any> = new EventEmitter<any>();

  constructor(public layoutService: LayoutService) {}

  ngOnInit() {
    this.sessionService.user$.subscribe((user: any) => {
      if (user) {
        this.isRoles = user.roles;
        const rol = user.roles?.filter((item: any) => item === 'ADMIN');
        this.isRol = rol.length > 0 ? rol[0] : null;
      }
    });

    this.model = [
      {
        label: '',
        items: [
          {
            label: 'Usuarios',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/', 'admin', 'users'],
            roles: [ERoles.ADMIN],
          },
          {
            label: 'Clientes',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/', 'admin', 'clients'],
            roles: [ERoles.ADMIN],
          },
          {
            label: 'Marcas',
            icon: 'pi pi-fw pi-star',
            routerLink: ['/', 'admin', 'brands'],
            roles: [ERoles.ADMIN, ERoles.SELLER_ADMIN],
          },
          {
            label: 'Competidores',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/', 'admin', 'competitors'],
            roles: [ERoles.ADMIN, ERoles.SELLER_ADMIN],
          },
          {
            label: 'Productos',
            icon: 'pi pi-fw pi-briefcase',
            routerLink: ['/', 'admin', 'products'],
            roles: [ERoles.ADMIN],
          },
          {
            label: 'Lista de precios',
            icon: 'pi pi-fw pi-qrcode',
            routerLink: ['/', 'admin', 'listprice'],
            roles: [ERoles.ADMIN, ERoles.SELLER_ONE, ERoles.SELLER_TWO, ERoles.DISTRIBUTOR],
          },
          {
            label: 'Licitaciones',
            icon: 'pi pi-fw pi-book',
            routerLink: ['/', 'admin', 'tenders'],
            roles: [ERoles.ADMIN, ERoles.SELLER_ONE],
          },
          {
            label: 'Cotizaciones',
            icon: 'pi pi-fw pi-book',
            roles: [ERoles.ADMIN, ERoles.SELLER_TWO],
            routerLink: ['/', 'admin', 'quotations'],
          },
          {
            label: 'Comparativas',
            icon: 'pi pi-fw pi-bars',
            routerLink: ['/', 'admin', 'comparatives'],
            roles: [ERoles.ADMIN, ERoles.SELLER_ADMIN],
          },
          {
            label: 'Informes',
            icon: 'pi pi-fw pi-exclamation-circle',
            roles: [ERoles.ADMIN],
            command: () => this.validExpand(),
            items: [
              {
                label: 'Productos por cliente',
                icon: '',
                routerLink: ['/', 'admin', 'product-report', 'quantity'],
                roles: [ERoles.ADMIN],
                adminRoles: this.isRoles,
              },
              {
                label: 'Precio por producto',
                icon: '',
                routerLink: ['/', 'admin', 'product-report', 'prices'],
                roles: [ERoles.ADMIN],
                adminRoles: this.isRoles,
              },
              {
                label: 'Comparativa',
                icon: '',
                routerLink: ['/', 'admin', 'competitors-report'],
                roles: [ERoles.ADMIN],
                adminRoles: this.isRoles,
              },
            ],
          },
          {
            label: 'Sincronizaciones',
            icon: 'pi pi-spinner',
            roles: [ERoles.ADMIN],
            command: () => this.validExpand(),
            items: [
              {
                label: 'Situación IVA',
                icon: '',
                routerLink: ['/', 'admin', 'sitiva'],
                roles: [ERoles.ADMIN],
                adminRoles: this.isRoles,
              },
              {
                label: 'Condición Venta',
                icon: '',
                routerLink: ['/', 'admin', 'condvta'],
                roles: [ERoles.ADMIN],
                adminRoles: this.isRoles,
              },
              {
                label: 'Provincias',
                icon: '',
                routerLink: ['/', 'admin', 'pvr'],
                roles: [ERoles.ADMIN],
                adminRoles: this.isRoles,
              },
              {
                label: 'Proyectos',
                icon: '',
                routerLink: ['/', 'admin', 'projects'],
                roles: [ERoles.ADMIN],
                adminRoles: this.isRoles,
              },
              {
                label: 'Ingresos brutos',
                icon: '',
                routerLink: ['/', 'admin', 'ib'],
                roles: [ERoles.ADMIN],
                adminRoles: this.isRoles,
              },
            ],
          },
        ],
      },
    ];
  }

  validExpand() {
    setTimeout(() => {
      this.isExpand = this.isExpand ? '' : 'btn-bottom-logout';
      this.onExpand.emit({ isExpand: this.isExpand });
      //this.model[0].items[12].class = this.isExpand;
    }, 200);
  }
}
