import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { SessionService } from '@core/auth/session.service';
import { map } from 'rxjs/operators';

export const authGuard: CanMatchFn = (route, state) => {
    const sessionService = inject(SessionService);
    const router = inject(Router);

    return sessionService.user$.pipe(map((isLoggedIn) => isLoggedIn || router.createUrlTree(['/auth/login'])));
};
