import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { LayoutService } from '../layout.service';
import { MenuComponent } from '../menu/menu.component';
import { MenuitemComponent } from '../menu/menuitem.component';

@Component({
    selector: 'app-sidebar',
    standalone: true,
    templateUrl: './sidebar.component.html',
    imports: [MenuComponent],
})
export class SidebarComponent implements OnInit {
    @Output()
    onExpand: EventEmitter<any> = new EventEmitter<any>();

    constructor(public layoutService: LayoutService, public el: ElementRef) {}

    ngOnInit(): void {}

    btnExpand(event: any) {
        this.onExpand.emit(event);
    }
}
