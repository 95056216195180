<div class="layout-topbar">
    <a class="layout-topbar-logo">
        <img src="/images/logo.png" alt="logo" />
    </a>

    <p-confirmDialog [closable]="false" [styleClass]="'alert-dialog'"></p-confirmDialog>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="showMenu($event)">
        <i class="pi pi-ellipsis-v"></i>
    </button>
    <div #topbarmenu class="layout-topbar-menu">
        <button class="p-link layout-topbar-button layout-topbar-profile" (click)="showMenu($event)">
            <div class="image">{{ user.icon }}</div>
            <div class="profile">
                <div>{{ user.name }}</div>
                <div>{{ user.profile }}</div>
            </div>
        </button>

        <p-tieredMenu #userMenu appendTo="body" [model]="itemsMenu" [popup]="true"></p-tieredMenu>
    </div>
</div>
