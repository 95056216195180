import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { ErrorComponent } from '@auth/error/error.component';
import { authGuard } from '@core/guards/auth.guard';
import { loginGuard } from '@core/guards/login.guard';

export const routes: Routes = [
  {
    path: 'admin',
    canActivate: [authGuard],
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/pages.routes').then((m) => m.routes),
      },
    ],
  },
  {
    path: 'auth',
    canActivate: [loginGuard],
    loadChildren: () => import('./auth/auth.routes').then((m) => m.authRouter),
  },
  { path: 'error', redirectTo: '/admin' },
  { path: '**', redirectTo: '/auth/login' },
];
