import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { LayoutService } from '../layout.service';
import { Router, RouterLink } from '@angular/router';
import { TieredMenu, TieredMenuModule } from 'primeng/tieredmenu';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { SessionService } from '@core/auth/session.service';
import { ERoles } from '@shared/enum';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
    selector: 'app-topbar',
    standalone: true,
    templateUrl: './topbar.component.html',
    imports: [RouterLink, NgClass, TieredMenuModule, ConfirmDialogModule],
       providers: [ConfirmationService]
})
export class TopbarComponent implements OnInit {
    private router = inject(Router);
    private sessionService = inject(SessionService);
    private confirmationService = inject(ConfirmationService);
    public layoutService = inject(LayoutService);

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    @ViewChild('userMenu') userMenu!: TieredMenu;

    itemsMenu: MenuItem[] = [];
    user: any;

    ngOnInit(): void {
        this.itemsMenu = [
            {
                label: 'Perfil',
                icon: 'pi pi-user',
                command: () => {
                    this.router.navigateByUrl('/admin/users/profile');
                },
            },
            {
                label: 'Cerrar sesión',
                icon: 'pi pi-sign-out',
                command: () => this.logoutAlert(),
            },
        ];

        this.sessionService.user$.subscribe((resp: any) => {
            if (resp) {
                const list = [
                    { key: ERoles.ADMIN, name: 'Administrador' },
                    { key: ERoles.SELLER_ADMIN, name: 'Vendedor Admin' },
                    { key: ERoles.DISTRIBUTOR, name: 'Distribuidor' },
                    { key: ERoles.SELLER_ONE, name: 'Vendedor' },
                    { key: ERoles.SELLER_TWO, name: 'Vendedor' },
                ];

                const isProfile = resp.roles.filter((item: any) => item === ERoles.ADMIN);
                const profile: any = isProfile.length ? isProfile[0] : resp.roles[0];
                this.user = resp;
                this.user.icon = this.user.name.substring(0, 2);

                list.map((item) => {
                    if (item.key === profile) {
                        this.user.profile = item.name;
                    }
                });
            }
        });
    }

    showMenu(event: Event) {
        this.userMenu.toggle(event);
    }

    logoutAlert() {
        this.confirmationService.confirm({
            message: '¿Estás seguro de cerrar sesión?',
            icon: 'pi pi-exclamation-triangle',
            acceptIcon: 'none',
            rejectIcon: 'none',
            acceptLabel: 'Aceptar',
            rejectLabel: 'Cancelar',
            acceptButtonStyleClass: 'alert p-button',
            rejectButtonStyleClass: 'alert-text p-button-text',
            accept: () => {
                this.sessionService.signOf();
            },
            reject: () => {},
        });
    }
}
