import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpGroupService } from '../http/http-group.service';
import { HttpUSerService } from '../http/http-user.service';
import { HttpClientService } from '../http/http-client.service';
import { HttpProjectService } from '../http/http-project.service';

@Injectable({
  providedIn: 'root',
})
export class InitAppService {
  private httpGroupService = inject(HttpGroupService);
  private httpUSerService = inject(HttpUSerService);
  private httpCLientService = inject(HttpClientService);
  private httpProjectService = inject(HttpProjectService);

  initServices() {
    //ver si es admin sino no cargar
    this.httpGroupService.init();
    this.httpUSerService.findListSeller();
  }
}
