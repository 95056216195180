import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { LocaleService } from './core/services/logic/locale.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { InitAppService } from '@core/services/logic/init.setvice';
import { SessionService } from '@core/auth/session.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, ConfirmDialogModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'igaltex';

  constructor(
    public primeNGConfig: PrimeNGConfig,
    private localeService: LocaleService,
    initService: InitAppService,
    sesionService: SessionService,
  ) {
    if (sesionService.getLocalUser() != null) {
      initService.initServices();
    }
  }

  ngOnInit() {
    const locale = this.localeService.getLocale();
    this.primeNGConfig.ripple = false;
    this.primeNGConfig.setTranslation(locale);
  }
}
