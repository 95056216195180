import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { SessionService } from '@core/auth/session.service';

export const loginGuard: CanMatchFn = (route, state) => {
  const sessionService = inject(SessionService);
  const router = inject(Router);

  const user = sessionService.getUser;

  if (user) {
    return router.createUrlTree(['/admin/users']);
  }

  return true;
};
