import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SessionService } from '@core/auth/session.service';
import { MessageService } from 'primeng/api';

export const jwtInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next) => {
  const sessionService = inject(SessionService);
  const messageService = inject(MessageService);
  const router = inject(Router);

  const user: any = sessionService.getUser;

  if (user && user.jwt) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${user.jwt}`,
      },
    });
  }
  const allowedWords = ['auth'];

  if (allowedWords.some((word) => req.url.includes(word))) {
    return next(req);
  }

  return next(req).pipe(
    map((response) => response),
    catchError((err) => {
      const { error } = err;
      console.log(error);
      let message = error?.message ?? '';
      const code = error?.statusCode || error?.code;

      if (code == 23505) {
        message = 'Clave duplicada';
      }

      if (message === 'Contraseña incorrecta') {
        message =
          'La contraseña actual ingresada no es correcta. Por favor, verifica y vuelve a intentarlo. En caso de que el error persista, contacta su administrador';
      }

      messageService.add({
        severity: 'error',
        summary: 'Error ' + code,
        detail: message ?? 'Error desconocido',
      });
      return throwError(error);
    }),
  );
};
